import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';

import components, { Layout } from '../components/index';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class NotFound extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className="container">
          <div className="py-5">
            <h1 className="my-5">Not found</h1>
            <h2 className="my-5">404</h2>
          </div>
        </div>
      </Layout>
    );
  }
}
